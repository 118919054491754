<template>
  <div class="quotaForm">
    <div v-if="!canEdit" class="quotaForm__disabled" />
    <el-form
      ref="formRuleQuota"
      :model="formRuleQuota"
      :rules="rules"
      label-width="120px"
      @submit.native.prevent
    >
      <div class="quotaForm__time-content">
        <div class="quotaForm__time-wrap">
          <div class="quotaForm__input-label">
            <span>{{ isMobile ? 'Дата начала' : 'с' }}</span>
          </div>

          <IqSelectDateForm
            v-model="formRuleQuota.date_from"
            :options="pickerOptions"
            type="date"
            format="dd.MM.yyyy"
            :placeholder="datePlaceholder"
            :disabled="isEditQuota"
            prop="date_from"
            size="large"
            :icon="isMobile"
            :width="datepickerWidth"
            :prefix="inputPrefix"
            @onChange="handleCheckDateFrom"
          />

          <div class="quotaForm__input-timeLabel">
            <span>{{ isMobile ? 'Время начала' : '-' }}</span>
          </div>

          <IqSelectTimeForm
            v-model="formRuleQuota.time_from"
            :picker-options="{
              start: '00:00',
              step: '01:00',
              end: '24:00',
              minTime: getMinTimeFrom,
            }"
            prop="time_from"
            size="large"
            :placeholder="timePlaceholder"
            :disabled="isEditQuota"
            :prefix="inputPrefix"
            :width="timerWidth"
            :icon="isMobile"
            @onChange="handleSetAutoTotal"
          />
        </div>

        <div class="quotaForm__time-wrap">
          <div class="quotaForm__input-label">
            <span>{{ isMobile ? 'Дата окончания' : 'по' }}</span>
          </div>

          <IqSelectDateForm
            v-model="formRuleQuota.date_to"
            :options="pickerOptionsDateTo"
            type="date"
            format="dd.MM.yyyy"
            :placeholder="datePlaceholder"
            :disabled="isDisableDateTo"
            size="large"
            :icon="isMobile"
            :width="datepickerWidth"
            :prefix="inputPrefix"
            prop="date_to"
            @onChange="handleCheckDateTo"
          />

          <div class="quotaForm__input-timeLabel">
            <span>{{ isMobile ? 'Время окончания' : '-' }}</span>
          </div>

          <IqSelectTimeForm
            v-model="formRuleQuota.time_to"
            :picker-options="{
              start: '00:00',
              step: '01:00',
              end: '24:00',
              minTime: getMinTimeTo,
            }"
            prop="time_to"
            size="large"
            :placeholder="timePlaceholder"
            :disabled="isDisableTimeTo"
            :prefix="inputPrefix"
            :width="timerWidth"
            :icon="isMobile"
            @onChange="handleSetAutoTotal"
          />
        </div>
      </div>

      <multiple-form-item
        field="cultures_ids"
        label="Культура"
        message="Выберите культуру"
        placeholder="Выберите культуру"
        :items="formRuleQuota.cultures_ids"
        :list="preparedCultureList"
        :disabled="isEditQuota"
        @set="onChangeField"
        @action="handleCultureField"
      />

      <div class="exporter__select">
        <iq-select-form
          v-model="formRuleQuota.exporter_id"
          :disabled="isEditQuota || exportersList.length === 1"
          :options="exportersList"
          full-width
          label="Экспортер"
          prop="exporter_id"
          key-label="name"
          key-value="id"
          placeholder="Укажите экспортера"
          label-width="118px"
          class="mr-40"
        />
      </div>

      <iq-input-form
        v-model="formRuleQuota.count"
        v-mask="'######'"
        inputmode="numeric"
        label="Кол-во авто в час не более"
        full-width
        prop="count"
        size="large"
        label-width="300px"
        :class="['autoInHour', inputFormMR]"
        @onInput="handleSetAutoTotal"
      />

      <iq-input-form
        v-model="formRuleQuota.totalCount"
        v-mask="'######'"
        inputmode="numeric"
        full-width
        label="Всего планируется авто"
        prop="totalCount"
        size="large"
        label-width="300px"
        :class="inputFormMR"
        :disabled="disabledCountOfAuto"
        @onInput="changeTotalCount"
      />

      <div class="quotaForm__total-validation">
        <p v-if="showMessage" style="color: #f56c6c; font-size: 12px">
          {{ `Макс. кол-во авто не может превышать ${maxTotalCount}` }}
        </p>
      </div>

      <el-checkbox
        v-if="!isEditQuota"
        v-model="formRuleQuota.improveToCommon"
        label="Распределить все таймслоты в общую квоту"
        class="m-b-7 m-t-7"
      />

      <el-collapse v-model="showTruckTypes" class="m-b-20">
        <el-collapse-item name="1">
          <template slot="title">
            <div class="quotaForm__collapse-head">
              <div class="quotaForm__collapse-title">Настроить тип авто</div>
              <div class="quotaForm__collapse-subtitle">
                (По умолчанию: любые)
              </div>
            </div>
          </template>
          <el-form-item :rules="rules.truck_type" prop="truck_type">
            <el-checkbox-group
              v-model="formRuleQuota.truck_type"
              :disabled="isEditQuota"
              class="truck-types"
            >
              <el-checkbox
                v-for="type in autoTypes"
                :key="type.id"
                :label="type.val"
              >
                {{ type.name }}
              </el-checkbox>
            </el-checkbox-group>
          </el-form-item>
        </el-collapse-item>
      </el-collapse>

      <div class="add-rule-culture__btns df jc-fe">
        <iq-button
          size="small"
          :color="isEditQuota ? 'main' : 'gray'"
          :disabled="!canEdit"
          @onClick="handleSubmitForm()"
        >
          {{ getTextBtn }} и закрыть
        </iq-button>

        <iq-button
          v-if="!isEditQuota"
          size="small"
          @onClick="handleSubmitForm(false)"
        >
          Добавить и продолжить
        </iq-button>
      </div>
    </el-form>
  </div>
</template>

<script>
import { ADD_QUOTA, UPDATE_EXPORTER_QUOTA } from '@/views/home/store/actions'
import { DATE_FORMAT, GET_TIME, MILLISECOND_IN_DAY } from '@/constants/date'
import { DIALOG_ADD_QUOTA } from '@/constants/dialogs'
import {
  GET_CULTURE_FROM_STATE,
  GET_EXPORTERS_ALL,
} from '@/views/control/store/actions'
import { GET_IS_MOBILE, GET_TERMINAL_CURRENT_ID_SLUG } from '@/store/actions'
import { autoType } from '@/constants/auto-type'
import {
  diffTime,
  getFinishTzDate,
  getQuotaTzDate,
  getTime,
  getTimeTo,
  getTzDate,
  getTzTime,
  isPickerDateToday,
  now,
  pickerOptions,
} from '@/core'
import { mapActions, mapGetters } from 'vuex'
import { ruleAddQuota } from '../data'
import Api from '@/api'
import IqButton from '@/views/ui/components/buttons/IqButton'
import IqInputForm from '@/views/ui/components/input/IqInputForm'
import IqSelectDateForm from '@/views/ui/components/select/IqSelectDateForm'
import IqSelectForm from '@/views/ui/components/select/IqSelectForm'
import IqSelectTimeForm from '@/views/ui/components/select/IqSelectTimeForm'
import MultipleFormItem from '@/UI/form/formItem/MultipleFormItem'
const { disabledDate } = pickerOptions

export default {
  name: 'CreateQuotaForm',
  components: {
    IqSelectForm,
    IqButton,
    IqInputForm,
    IqSelectTimeForm,
    IqSelectDateForm,
    MultipleFormItem,
  },
  props: {
    quota: {
      type: Object,
      default: () => ({}),
    },
    canEdit: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      maxTotalCount: null,
      showMessage: false,
      autoTypes: autoType.slice(1),
      preparedCultureList: [],
      pickerOptions,
      name: DIALOG_ADD_QUOTA,
      formRuleQuota: {
        cultures_ids: [undefined],
        exporter_id: undefined,
        count: undefined,
        totalCount: undefined,
        date_from: null,
        date_to: null,
        time_from: '',
        time_to: '',
        improveToCommon: false,
        truck_type: [],
      },
      rules: ruleAddQuota,
      pickerOptionsDateFrom: {
        disabledDate,
      },
      pickerOptionsDateTo: {
        disabledDate: time =>
          this.formRuleQuota?.date_from > time.getTime() ||
          time.getTime() > this.formRuleQuota?.date_from + MILLISECOND_IN_DAY,
      },
      showTruckTypes: '',
    }
  },

  computed: {
    ...mapGetters({
      terminalSlug: GET_TERMINAL_CURRENT_ID_SLUG,
      cultureList: GET_CULTURE_FROM_STATE,
      exportersList: GET_EXPORTERS_ALL,
      isMobile: GET_IS_MOBILE,
    }),
    disabledCountOfAuto() {
      const { date_from, date_to, time_from, time_to, count } =
        this.formRuleQuota

      return !(date_from && date_to && time_from && time_to && count)
    },
    isEditQuota() {
      return Boolean(this.quota?.isEdit)
    },
    getTextBtn() {
      return this.isEditQuota ? 'Обновить' : 'Добавить'
    },
    isDisableDateTo() {
      return !this.formRuleQuota.date_from || this.isEditQuota
    },
    isDisableTimeTo() {
      return (
        this.formRuleQuota.time_from === '' ||
        this.isEditQuota ||
        !this.formRuleQuota.date_to
      )
    },
    getMinTimeFrom() {
      return isPickerDateToday(this.formRuleQuota.date_from)
        ? getTzTime(now(), GET_TIME)
        : ''
    },
    getMinTimeTo() {
      if (
        getTzDate(this.formRuleQuota.date_from, 'X') ===
        getTzDate(this.formRuleQuota.date_to, 'X')
      ) {
        return this.formRuleQuota.time_from
      }

      return ''
    },
    allTruckTypesOptions() {
      return autoType.slice(1).map(item => item.val)
    },

    datepickerWidth() {
      return this.isMobile ? '100%' : '135px'
    },
    timerWidth() {
      return this.isMobile ? '100%' : '100px'
    },
    datePlaceholder() {
      return this.isMobile ? 'Выбрать дату' : ''
    },
    timePlaceholder() {
      return this.isMobile ? 'Выбрать время' : ''
    },
    inputPrefix() {
      return this.isMobile ? 'none' : ''
    },
    inputFormMR() {
      return window.innerWidth < '550' ? '' : 'mr-40'
    },
  },
  watch: {
    quota() {
      this.initQuotaData()
    },
    cultureList: {
      handler(value) {
        let sortedList = [...value]

        sortedList = sortedList.sort((a, b) => a.name.localeCompare(b.name))
        this.preparedCultureList = sortedList.map(item => ({
          id: item.id,
          unload_id: item.unload_id,
          name: `${item.name} ${item.harvest_year ? item.harvest_year : ''}`,
          harvest_year: `${item.harvest_year}`,
        }))
      },
      deep: true,
      immediate: true,
    },
  },
  mounted() {
    this.initQuotaData()
  },
  methods: {
    ...mapActions({
      addQuota: ADD_QUOTA,
      updateQuota: UPDATE_EXPORTER_QUOTA,
    }),
    initQuotaData() {
      const newVal = this.quota

      if (this.isEditQuota) {
        this.setFormFields(
          newVal.cultures_ids,
          newVal.trucks_per_hour,
          newVal.trucks_allowed_count,
          getTzDate(newVal.time_from),
          getFinishTzDate(newVal.time_to),
          getTime(newVal.time_from, GET_TIME),
          getTimeTo(newVal.time_to, GET_TIME),
          !newVal.truck_type_codes.length ||
            newVal.truck_type_codes.length === autoType.length - 1
            ? this.allTruckTypesOptions
            : newVal.truck_type_codes,
          newVal.exporters_ids[0],
        )

        const { date_from, date_to, time_from, time_to, count } =
          this.formRuleQuota

        if (date_from && date_to && time_from && time_to && count) {
          const totalTimeFrom = `${getTime(
            date_from,
            DATE_FORMAT,
          )} ${time_from}`
          const totalTimeTo = `${getTime(date_to, DATE_FORMAT)} ${time_to}`
          const diffTimeFromTo = diffTime(totalTimeTo, totalTimeFrom, 'minutes')

          this.maxTotalCount = Math.ceil((diffTimeFromTo / 60) * count)
        }
        // разрешаем изменения в блоке распределения квоты (пока ничего не меняли здесь)
        this.$emit('changed', false)
      } else {
        this.setFormFields()

        // если у терминала один экспортер - хардкодим его и дизейблим
        if (this.exportersList.length === 1) {
          this.formRuleQuota.exporter_id = this.exportersList[0].id
        }
      }
    },
    changeTotalCount(val) {
      this.formRuleQuota.totalCount =
        val > this.maxTotalCount ? this.maxTotalCount : val
      this.showMessage = val > this.maxTotalCount
      setTimeout(() => {
        this.showMessage = false
      }, 1000)

      // для запрета изменений блока распределения квот в режиме редактирования
      this.$emit('changed', true)
    },
    handleCultureField(i, type) {
      try {
        if (i >= 0) {
          this.formRuleQuota[type]?.splice(i, 1)
        } else {
          this.formRuleQuota[type]?.push(undefined)
        }
      } catch {
        console.log(`Не хватает полей в ${type}`)
      }
    },
    onChangeField(val, index, type) {
      this.formRuleQuota[type][index] = val
    },
    handleSubmitForm(close = true) {
      this.$refs.formRuleQuota.validate(valid => {
        if (valid) {
          if (this.isEditQuota) {
            const requestData = {
              parent_id: this.quota.id,
              trucks_per_hour: this.formRuleQuota.count,
              trucks_allowed_count: this.formRuleQuota.totalCount,
            }

            this.updateQuota(requestData)
          } else {
            const {
              cultures_ids,
              exporter_id,
              count,
              totalCount,
              truck_type,
              date_from,
              time_from,
              date_to,
              time_to,
              improveToCommon,
            } = this.formRuleQuota

            const requestData = {
              cultures_ids,
              is_proxy: 1,
              time_from: getQuotaTzDate(date_from, time_from),
              time_to: getQuotaTzDate(date_to, time_to),
              trucks_allowed_count: totalCount,
              trucks_per_hour: count,
              exporter_must_be_set: 0,
              exporters_ids: [exporter_id],
              truck_type_codes: truck_type,
            }

            let callback = improveToCommon ? this.improveHard : () => {}

            this.addQuota({ data: requestData, callback })
          }

          this.updateAfterAccept()

          if (close) {
            this.setDialog({ name: DIALOG_ADD_QUOTA })
          }
        }
      })
    },
    setFormFields(
      cultures_ids = [undefined],
      count = undefined,
      totalCount = undefined,
      date_from = null,
      date_to = null,
      time_from = '',
      time_to = '',
      truck_type = this.allTruckTypesOptions,
      exporter_id = undefined,
      improveToCommon = false,
    ) {
      this.formRuleQuota.cultures_ids = cultures_ids
      this.formRuleQuota.count = count
      this.formRuleQuota.totalCount = totalCount
      this.formRuleQuota.date_from = date_from
      this.formRuleQuota.date_to = date_to
      this.formRuleQuota.time_from = time_from
      this.formRuleQuota.time_to = time_to
      this.formRuleQuota.truck_type = truck_type
      this.formRuleQuota.exporter_id = exporter_id
      this.formRuleQuota.improveToCommon = improveToCommon
      this.$refs.formRuleQuota.clearValidate()
    },
    updateAfterAccept() {
      this.$refs.formRuleQuota.resetFields()
      this.setFormFields()
      this.$nextTick(() => {
        this.$refs.formRuleQuota.clearValidate('cultures_ids.0')
        this.$refs.formRuleQuota.clearValidate('exporter_id')
      })

      // если у терминала один экспортер - хардкодим его и дизейблим
      if (this.exportersList.length === 1) {
        this.formRuleQuota.exporter_id = this.exportersList[0].id
      }
    },
    handleCheckDateFrom() {
      if (this.formRuleQuota.date_from > this.formRuleQuota.date_to) {
        this.formRuleQuota.date_to = null
      }
      if (
        getTime(this.formRuleQuota.date_from, DATE_FORMAT) ===
        getTime(now(), DATE_FORMAT)
      ) {
        this.formRuleQuota.time_from = null
      }
      this.handleSetAutoTotal()
    },
    handleCheckDateTo() {
      if (this.formRuleQuota.date_from > this.formRuleQuota.date_to) {
        this.formRuleQuota.date_to = null
      }
      this.handleSetAutoTotal()
    },
    handleSetAutoTotal() {
      if (this.showMessage) {
        this.showMessage = false
      }
      const { date_from, date_to, time_from, time_to, count } =
        this.formRuleQuota

      if (date_from && date_to && time_from && time_to && count) {
        const totalTimeFrom = `${getTime(date_from, DATE_FORMAT)} ${time_from}`
        const totalTimeTo = `${getTime(date_to, DATE_FORMAT)} ${time_to}`
        const diffTimeFromTo = diffTime(totalTimeTo, totalTimeFrom, 'minutes')

        this.formRuleQuota.totalCount = Math.ceil((diffTimeFromTo / 60) * count)
        this.maxTotalCount = this.formRuleQuota.totalCount
      } else {
        this.formRuleQuota.totalCount = undefined
        this.maxTotalCount = null
      }
      // для запрета изменений блока распределения квот в режиме редактирования
      this.$emit('changed', true)
    },
    // принудительное распределение всех таймлотов в общую квоту
    async improveHard(id, quantity) {
      let requestData = {
        parent_id: id,
        quota: quantity,
      }

      try {
        await Api.quota.saveSupplierQuota(requestData)
      } catch (e) {
        console.log(e.response.data)
      }
    },
  },
}
</script>

<style lang="sass">
.quotaForm
  position: relative

  &__time-content
    display: flex
    justify-content: flex-start
    align-items: center
    margin-bottom: 20px

  &__time-wrap
    display: flex
    justify-content: space-between
    align-items: center
    flex: 0 1 50%
    & + &
      margin-left: 10px

  &__input-wrap
    margin-bottom: 0 !important
    .el-form-item__content
      margin-left: 0 !important

  &__input
    &--date.el-input
      margin-right: 10px
      width: 130px
    &--time.el-input
      width: 95px

  .autoInHour
    margin-bottom: 22px !important

  &__count-wrap
    .el-form-item__label
    width: auto !important
    .el-form-item__content
      margin-left: 0 !important

  &__collapse-title
    font-size: 20px
    line-height: 1
    margin-bottom: 5px
    color: #606266

  &__collapse-subtitle
    line-height: 1
    color: #606266

  .el-collapse-item__content
    padding: 10px 0

    .el-form-item
      margin-bottom: 0

    .el-form-item__content
      margin-left: 0 !important

    .el-radio-group
      display: flex
      justify-content: flex-start
      align-items: flex-start
      flex-wrap: wrap
      .el-radio
        flex: 0 0 100%
        margin: 0 0 10px 0
        display: flex
        flex-direction: row-reverse
        justify-content: space-between
        &.is-bordered.is-checked
          border-color: $color-blue-light

    .el-radio__input.is-checked .el-radio__inner
      border-color: $color-blue-light
      background: $color-blue-light

    .el-radio__input.is-checked+.el-radio__label
      color: $color-blue-light

  .el-select .el-input.is-disabled .el-input__inner
    background-color: #F5F7FA !important
    border-color: #E4E7ED !important
    color: #C0C4CC !important
    cursor: not-allowed

  .iq-date-picker.el-form-item,
  .iq-time-picker
    margin-bottom: 0 !important
  .mr-40
    margin-right: 40px
  &__total-validation
    height: 20px
    margin-left: 168px
    margin-top: -15px
    @media (max-width: 1199px)
      margin-left: 0

  .truck-types
    display: flex
    flex-direction: column

  &__disabled
    position: absolute
    width: 100%
    height: 100%
    z-index: 50

@media (max-width: 1199px)
  .quotaForm
    &__time-content
      flex-direction: column
      .el-input--suffix .el-input__inner
        padding-left: 12px
    &__time-wrap
      flex-direction: column
      align-items: flex-start
      column-gap: 6px
      width: 100%
    .dialog-add-quota__time-wrap + .dialog-add-quota__time-wrap
      margin-left: 0
    &__time-wrap:first-child
      margin-bottom: 14px
    .el-form-item.iq-date-picker, .el-form-item.iq-time-picker
      width: 100% !important
      margin-bottom: 17px !important
    &__input-label, &__input-timeLabel
      margin-bottom: 6px
    &__input-label
      min-width: 20px
  .add-rule-culture__btns
    justify-content: center
    flex-direction: column
    .el-button
      flex: 1
    .el-button + .el-button
      margin: 10px 0 0

.exporter__select
  margin-bottom: 20px
</style>
