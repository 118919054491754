import { render, staticRenderFns } from "./CreateQuotaForm.vue?vue&type=template&id=81968e40&"
import script from "./CreateQuotaForm.vue?vue&type=script&lang=js&"
export * from "./CreateQuotaForm.vue?vue&type=script&lang=js&"
import style0 from "./CreateQuotaForm.vue?vue&type=style&index=0&id=81968e40&lang=sass&"


/* normalize component */
import normalizer from "!../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/home/node/app/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('81968e40')) {
      api.createRecord('81968e40', component.options)
    } else {
      api.reload('81968e40', component.options)
    }
    module.hot.accept("./CreateQuotaForm.vue?vue&type=template&id=81968e40&", function () {
      api.rerender('81968e40', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/views/home/components/dialogs/dialogAddQuota/components/CreateQuotaForm.vue"
export default component.exports